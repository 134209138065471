// 采用npm方式引入
const BrowserLogger = require('alife-logger')

let env = process.env.VUE_APP_ENV
let envMap = {
  dev: 'local',
  pro: 'pro',
  fat: 'daily',
  beta: 'daily',
  uat: 'pre'
}
// BrowserLogger.singleton(conf) conf传入config配置
window.__bl = BrowserLogger.singleton({
  pid: 'csuy1g6r8k@8e425dac8428657',
  appType: 'web',
  imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
  behavior: true,
  enableSPA: false,
  useFmp: true,
  sendResource: true,
  environment: envMap[env],
  ignore: {
    ignoreUrls: ['api.growingio.com'],
    ignoreErrors: [
      /^Script error\.?$/,
      /window.pauseAllAudio/,
      /window.pauseYuwenAudio/,
      /window.pauseAllAudio/
    ]
  }
})
